<template>
  <div>
    <material-add></material-add>
  </div>
</template>

<script>
import MaterialAdd from './MaterialAdd.vue';

export default {
  components: {
    MaterialAdd,
  },

  data() {
    return {};
  },
};
</script>
